import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa'; 

import ProductImage from '../assets/images/bandsawblade-1.jpg';
import Spacing from './Spacing';

const Quantity5: React.FC = () => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  return (
    <Container className="select-size custom-card product-card card sticky-column">
      <div className='cart-card'>
      
        <Col>
          <img src={ProductImage} alt="Product" className="img-fluid p-image" />
        </Col>
            
        <div className='cart-card-body cart-card-body3'>
          <Container>

            <Row className="align-items-center mt-3">
              <Col>
                <div className="price-section">
                  <span className="current-price">$19.35</span>
                </div>
              </Col>
              <Col className='counter'>
                <Row>
                  <Col xs="4">
                    <Button variant='light' className="btn-wave fs-18 equal-width-btn btn-grey-color" onClick={decreaseQuantity}>
                      <FaMinus /> {/* Minus icon */}
                    </Button>
                  </Col>
                  <Col xs="4" className='quantity-label'>
                    <span style={{ fontSize: '18px' }}>{quantity}</span>
                  </Col>
                  <Col xs="4">
                    <Button variant='light' className="btn-wave fs-18 equal-width-btn btn-grey-color" onClick={increaseQuantity}>
                      <FaPlus /> {/* Plus icon */}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="align-items-center mt-1 quantity-row">
              <Col className='quantity-txt'>
                <Button variant='danger' className="btn-add-to-cart">
                  Add to Cart
                </Button>
              </Col>
            </Row>

            <div className='divider'></div>

            <Spacing height={10} />

            <Row className="align-items-center">
              <Col className='text-left'>
                <div>
                  <span className='shipping-title'>Estimated to Ship 11/20 - 11/22</span>
                </div>
                <div>
                  <span className='shipping-subtitle'>Ship It Free (Lower 48 States) See Shipping Options</span>
                </div>
              </Col>
            </Row>
            
          </Container>
        </div>
      </div>
    </Container>
  );
};

export default Quantity5;

import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; 
import ApplicationImg from '../assets/images/application-title-img.png';
import { Image } from 'react-bootstrap';

const BestForList: React.FC = () => {
  return (
    <div className="cart-card">
      <div className="cart-card-body6">
        <div className="best-for-container mt-1">
          <p className="best-for-title">BEST FOR</p>
          <Image src={ApplicationImg} width={180} height={30} fluid className="mb-3" />
          <ul className="best-for-list">
            <li><FaCheckCircle className="icon" /> Tool Steels</li>
            <li><FaCheckCircle className="icon" /> Alloy Steels</li>
            <li><FaCheckCircle className="icon" /> Titanium Alloys</li>
            <li><FaCheckCircle className="icon" /> Bearing Steels</li>
            <li><FaCheckCircle className="icon" /> Structural Steels</li>
            <li><FaCheckCircle className="icon" /> Stainless Steels</li>
            <li><FaCheckCircle className="icon" /> Mold Steels</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BestForList;

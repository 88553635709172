import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  Button,
  Card,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { decimalToTime, formatNumber } from "../utils/formatter";

const SpeedFeedRecommendations = () => {
  const { calculateResult } = useSelector(
    (state: RootState) => state.speedFeedManager
  );

  const { machineDetail, resultArr } = calculateResult;

  const getQSeriesImage = (qseries: string) => {
    const imageName = `ch-${qseries.toLowerCase()}-q.png`;
    try {
      return require(`../assets/images/${imageName}`);
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      {machineDetail.machine !== "" && (
        <h5 className="py-2 px-4 text-center">
          For a {machineDetail.machine} Model: {machineDetail.model} Blade
          Length: {machineDetail.bladeSize} ({machineDetail.lengthInches}")
        </h5>
      )}

      <Container>
        <div className="row g-0">
          {resultArr &&
            resultArr.map((result) => (
              <Card key={result.bladeseriesid} className="shadow mb-3">
                <Card.Header className="bandtype">
                  <h5 className="text-white">
                    {result.pages.map((page, i) => {
                      const imageSrc = getQSeriesImage(page.qseries);
                      return (
                        <span key={i}>
                          {imageSrc ? (
                            <img
                              src={imageSrc}
                              alt={page.qseries}
                              height="25"
                              className="me-2"
                            />
                          ) : (
                            <span>{page.qseries}</span>
                          )}
                        </span>
                      );
                    })}{" "}
                    {result.seriesLabel} Recommendations
                  </h5>
                </Card.Header>
                <Card.Body>
                  <Row className="gx-0">
                    <Col lg={6} md={6} sm={12}>
                      <Card className="border-0">
                        <Card.Header className="bg-gray250 text-dark pb-0">
                          <h6>Speed and Feed Recommendations</h6>
                        </Card.Header>
                        <Card.Body className="small text-dark">
                          <Row>
                            <Col sm={12} md={4} className="bg-light">
                              <strong>Material Stock</strong>
                            </Col>
                            <Col sm={12} md={8} className="bg-light">
                              {result.materialDimensions}
                              <br />
                              {result.materialDescription}
                              <br />
                              {result.heatTreatedDescription}
                            </Col>

                            <Col xs={12} md={4}>
                              <strong>Area to Cut</strong>
                            </Col>
                            <Col xs={12} md={8}>
                              Total Surface Area:{" "}
                              {formatNumber(result.areatocut)} sq. in.
                            </Col>

                            <Col xs={12} md={4} className="bg-light">
                              <strong>Cut Rate Range</strong>
                            </Col>
                            <Col xs={12} md={8} className="bg-light">
                              <span className="text-muted fw-lighter fst-italic">
                                Low:
                              </span>{" "}
                              {formatNumber(result.cutRateRangeLow)} SIPM
                              <br />
                              <span className="text-muted fw-lighter fst-italic">
                                High:
                              </span>{" "}
                              {formatNumber(result.cutRateRangeHigh)} SIPM
                            </Col>

                            <Col xs={12} md={4}>
                              <strong>Speed Range</strong>
                            </Col>
                            <Col xs={12} md={8}>
                              {formatNumber(result.speedRange)} FPM
                            </Col>
                            {/* </Row>
                          <Row> */}
                            <Col sm={12} className="bg-light">
                              <strong>Completion Times</strong>
                            </Col>
                            <Col xs={12} className="bg-light ps-4">
                              <span className="text-muted fw-lighter fst-italic">
                                High Production Rate:
                              </span>{" "}
                              {decimalToTime(
                                result.fallRate > result.rateMedium
                                  ? result.fallRate
                                  : result.rateHigh
                              )}{" "}
                              minutes ({formatNumber(result.timeHigh)} SIPM)
                              <br />
                              <span className="text-muted fw-lighter fst-italic">
                                Medium Production Rate:
                              </span>{" "}
                              {decimalToTime(
                                result.fallRate > result.rateMedium
                                  ? result.fallRate
                                  : result.rateMedium
                              )}{" "}
                              minutes ({formatNumber(result.timeMedium)} SIPM)
                              <br />
                              <span className="text-muted fw-lighter fst-italic">
                                Blade Saver Rate:
                              </span>{" "}
                              {decimalToTime(
                                result.fallRate > result.rateMedium
                                  ? result.fallRate
                                  : result.rateSaver
                              )}{" "}
                              minutes ({formatNumber(result.timeSaver)} SIPM)
                              <br />
                              {result.fallRate > result.rateMedium && (
                                <span className="text-primary small">
                                  <sup>*</sup> Cut times are limited due to saw
                                  head travel time.
                                </span>
                              )}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Card className="border-0">
                        <Card.Header className="bg-sbc-danger text-white pb-0">
                          <h6>Tooth Pitch Recommendations!</h6>
                        </Card.Header>
                        <Card.Body className="small d-flex flex-column">
                          {(result.tpStruct.best?.id > 0 ||
                            result.tpStruct.option1?.id > 0 ||
                            result.tpStruct.option2?.id > 0) && (
                            <Row className="w-100">
                              <Col xs={3} md={2}></Col>
                              <Col xs={2} className="tpicol">
                                &nbsp;
                              </Col>
                              <Col xs={2} className="widcol">
                                &nbsp;
                              </Col>
                              <Col xs={5} md={6}>
                                &nbsp;
                              </Col>
                            </Row>
                          )}

                          {result.tpStruct.best && (
                            <>
                              {result.tpStruct.best.id === 0 ? (
                                <Row>
                                  <Col xs={12}>
                                    <div
                                      className="text-danger"
                                      dangerouslySetInnerHTML={{
                                        __html: result.tpStruct.best.descr,
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col
                                    xs={3}
                                    md={2}
                                    className="small text-dark"
                                  >
                                    <strong>Best</strong>
                                  </Col>
                                  <Col xs={2} className="small text-center">
                                    {result.tpStruct.best.descr.replace(
                                      "Tooth Pitch",
                                      "TPI"
                                    )}
                                  </Col>
                                  <Col xs={7} md={8}>
                                    {result.tpStruct.best.bladeDetailForCart?.map(
                                      (blade) => (
                                        <Row key={blade.bladeId}>
                                          <Col
                                            xs={4}
                                            md={3}
                                            lg={2}
                                            className="small text-center"
                                          >
                                            {blade.thickness}
                                          </Col>

                                          <Col xs={8} md={6} lg={4}>
                                            <InputGroup className="input-group input-group-sm">
                                              <span className="input-group-text-tiny text-danger minus">
                                                -
                                              </span>
                                              <FormControl
                                                type="text"
                                                className="text-center my-0 mx-2 rounded-2 h-25"
                                                value={"1"}
                                                id={`qty_${blade.bladeId}`}
                                                min="1"
                                                readOnly
                                              />
                                              <span className="input-group-text-tiny text-success plus">
                                                +
                                              </span>
                                            </InputGroup>
                                          </Col>

                                          <Col
                                            xs={12}
                                            md={10}
                                            lg={6}
                                            className="text-center"
                                          >
                                            <Button
                                              variant="success"
                                              className="btn btn-sm w-50 btn-font me-1 fw-bold"
                                              data-id={blade.bladeId}
                                              id={`add_${blade.bladeId}`}
                                            >
                                              Add to Cart
                                            </Button>
                                            <span
                                              id={`price_${blade.bladeId}`}
                                              className="small text-primary fw-bold"
                                            >
                                              $3.23 ea.
                                            </span>
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  </Col>
                                </Row>
                              )}
                            </>
                          )}

                          {result.tpStruct.option1 && (
                            <>
                            {result.tpStruct.option1.id === 0 ? (
                                <Row>
                                  <Col xs={12}>
                                    <div
                                      className="text-danger"
                                      dangerouslySetInnerHTML={{
                                        __html: result.tpStruct.option1.descr,
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                            <Row className="bg-light py-1 my-1">
                              <Col xs={2} className="small text-dark">
                                <strong>Option 1</strong>
                              </Col>
                              <Col xs={2} className="small text-center">
                                {result.tpStruct.option1.descr.replace(
                                  "Tooth Pitch",
                                  "TPI"
                                )}
                              </Col>
                              <Col xs={8}>
                                {result.tpStruct.option1.bladeDetailForCart?.map(
                                  (blade) => (
                                    <Row className="" key={blade.bladeId}>
                                      <Col
                                        xs={4}
                                        md={3}
                                        lg={2}
                                        className="small text-center"
                                      >
                                        {blade.thickness}
                                      </Col>
                                      <Col xs={8} md={6} lg={4}>
                                        <InputGroup className="input-group input-group-sm">
                                          <span className="input-group-text-tiny text-danger minus">
                                            -
                                          </span>
                                          <FormControl
                                            type="text"
                                            className="text-center my-0 mx-2 rounded-2 h-25"
                                            value="1"
                                            id="qty_54"
                                            min="1"
                                            readOnly
                                          />
                                          <span className="input-group-text-tiny text-success plus">
                                            +
                                          </span>
                                        </InputGroup>
                                      </Col>
                                      <Col
                                        xs={12}
                                        md={10}
                                        lg={6}
                                        className="text-center"
                                      >
                                        <Button
                                          variant="success"
                                          className="btn btn-sm w-50 btn-font me-1 fw-bold"
                                          data-id={blade.bladeId}
                                          id={`add_${blade.bladeId}`}
                                        >
                                          Add to Cart
                                        </Button>
                                        <span
                                          id={`price_${blade.bladeId}`}
                                          className="small text-primary fw-bold"
                                        >
                                          $3.23 ea.
                                        </span>
                                      </Col>
                                    </Row>
                                  )
                                )}
                              </Col>
                            </Row>
                              )}
                            </>
                          )}

                          {result.tpStruct.option2 && (
                            <>
                            {result.tpStruct.option2.id === 0 ? (
                                <Row>
                                <Col xs={12}>
                                  <div
                                    className="text-danger"
                                    dangerouslySetInnerHTML={{
                                      __html: result.tpStruct.option2.descr,
                                    }}
                                  />
                                </Col>
                              </Row>
                            ) : (
                            <Row className="py-1 my-1">
                              <Col xs={2} className="small text-dark">
                                <strong>Option 2</strong>
                              </Col>
                              <Col xs={2} className="small text-center">
                                {result.tpStruct.option1.descr.replace(
                                  "Tooth Pitch",
                                  "TPI"
                                )}
                              </Col>
                              <Col xs={8}>
                                {result.tpStruct.option2.bladeDetailForCart?.map(
                                  (blade) => (
                                    <Row className="" key={blade.bladeId}>
                                      <Col
                                        xs={4}
                                        md={3}
                                        lg={2}
                                        className="small text-center"
                                      >
                                        {blade.thickness}
                                      </Col>
                                      <Col xs={8} md={6} lg={4}>
                                        <InputGroup className="input-group input-group-sm">
                                          <span className="input-group-text-tiny text-danger minus">
                                            -
                                          </span>
                                          <FormControl
                                            type="text"
                                            className="text-center my-0 mx-2 rounded-2 h-25"
                                            value="1"
                                            id="qty_54"
                                            min="1"
                                            readOnly
                                          />
                                          <span className="input-group-text-tiny text-success plus">
                                            +
                                          </span>
                                        </InputGroup>
                                      </Col>
                                      <Col
                                        xs={12}
                                        md={10}
                                        lg={6}
                                        className="text-center"
                                      >
                                        <Button
                                          variant="success"
                                          className="btn btn-sm w-50 btn-font me-1 fw-bold"
                                          data-id={blade.bladeId}
                                          id={`add_${blade.bladeId}`}
                                        >
                                          Add to Cart
                                        </Button>
                                        <span
                                          id={`price_${blade.bladeId}`}
                                          className="small text-primary fw-bold"
                                        >
                                          $3.23 ea.
                                        </span>
                                      </Col>
                                    </Row>
                                  )
                                )}
                              </Col>
                            </Row>
                            )}
                            </>
                          )}

                          <Row className="mt-4">
                            <Col xs={12} md={7} className="small">
                              <img
                                src={require("../assets/images/qsaw-brand.jpg")}
                                style={{ maxWidth: "45%" }}
                                alt="Qsaw Brand"
                              />
                              <img
                                src={require("../assets/images/made-in-usa.jpg")}
                                style={{ maxWidth: "45%" }}
                                alt="Made in USA"
                              />
                              <br />
                              {result.metadata}
                            </Col>
                            <Col xs={12} md={5} className="d-none d-md-block">
                              <img
                                src="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F750684880-4fb4bec15b824774324392e2503a2936e35979b9bcf2ce46ed75765ec7a9949b-d_295x166&amp;src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                                className="w-100"
                                alt="Vimeo Video"
                                data-src="https://player.vimeo.com/video/101143567?h=9b803602d1&amp;color=ef0022&amp;title=0&amp;byline=0&amp;portrait=0"
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
        </div>
      </Container>
    </>
  );
};

export default SpeedFeedRecommendations;

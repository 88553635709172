import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Quantity6: React.FC = () => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  return (
    <Container className="quantity-component cart-card p-4 pt-3">
      <div className="cart-card-body  rounded p-0 card-price ">
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="label-text fw-bold">PRICE PER BLADE</span>
              <span className="price-text fw-bold">$30.84</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="label-text fw-bold">QUANTITY</span>
              <div className="d-flex align-items-center">
                <Button
                  variant="light"
                  className="btn-wave fs-18 equal-width-btn btn-grey-color shadow-sm"
                  onClick={decreaseQuantity}
                >
                  <FaMinus />
                </Button>
                <div className="quantity-display-box text-center mx-3 border border-secondary rounded p-2">
                  <span className="quantity-number">{quantity}</span>
                </div>
                <Button
                  variant="light"
                  className="btn-wave fs-18 equal-width-btn btn-grey-color shadow-sm"
                  onClick={increaseQuantity}
                >
                  <FaPlus />
                </Button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="label-text fw-bold">TOTAL</span>
              <span className="total-text fw-bold">$29.84*</span>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center mt-4">
          <Button variant="danger" className="btn-add-to-cart shadow-sm px-4">
            Add to Cart
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Quantity6;

import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-ca71926949/icons";
import { IBladeWidthData } from "../redux/features/speedFeedManagerSlice";

interface IBladeDropDownProps {
    bladeWidthData: IBladeWidthData[];
    onBladeWidthSelect: (data: number) => void;
    selectedBladeWidth: number;
}

const BladeWidrthDropDown: React.FC<IBladeDropDownProps> = ({
    bladeWidthData,
    onBladeWidthSelect,
    selectedBladeWidth,
}) => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseFloat(event.target.value);
    onBladeWidthSelect(selectedValue);
  };

  return (
    <Form.Group className="machineDrop">
      <Form.Label className="machineLabel fw-normal fs-6">
        <span className="icon-drop">
          <FontAwesomeIcon className="fs-3" icon={fas.faIndustry} />
        </span>
        Blade Width
      </Form.Label>
      <Form.Select
        value={selectedBladeWidth}
        onChange={handleSelect}
        aria-label="Blade Width Select"
      >
        <option value={0}>Select Blade Width</option>
        {bladeWidthData.map((blade) => (
          <option key={blade.widthid} value={blade.inch}>
            {blade.inch} in
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};
export default BladeWidrthDropDown;
